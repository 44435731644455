<template>
  <div>
    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="goods_no, goods_id, sku_id를 넣어주세요" v-model="form.search" @keypress.enter.prevent.stop="list" v-focus></b-form-input>
      </b-input-group>
      <shop-preset v-model="form.shop"></shop-preset>

      <b-row class="mb-2">
        <b-col lg="3">
          <small>goods_no</small>
          <b-form-textarea :rows="4" v-model="form.goods_no" placeholder="goods_no를 입력해주세요"></b-form-textarea>
        </b-col>
        <b-col lg="3">
          <small>goods_id</small>
          <b-form-textarea :rows="4" v-model="form.goods_id" placeholder="goods_id를 입력해주세요"></b-form-textarea>
        </b-col>
        <b-col lg="3">
          <small>gid</small>
          <b-form-textarea :rows="4" v-model="form.gid" placeholder="gid를 입력해주세요"></b-form-textarea>
        </b-col>
        <b-col lg="3">
          <small>한 번에 가져올 상품 수</small><br/>
          <b-form-input id="limit" type="text" placeholder="한 번에 가져올 상품 수" v-model.number="form.limit"></b-form-input>
        </b-col>
      </b-row>

      <b-button class="m-1" variant="primary" @click="list" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
    </b-card>

<!--    <div class="drop" @drop.prevent.stop="handleFile" @dragover="handleDragover" @dragenter="handleDragover"
         @click="_=>{$refs.fileInput.value = null;$refs.fileInput.click()}">
      Drop File or Click
    </div>
    <input type="file" ref="fileInput" style="display: none" @change="handleFile">-->

    <div class="mb-2 clearfix">
      <b-btn class="mr-1" variant="warning" @click="" :disabled="busy.run">이미지 선택 초기화<b-spinner class="ml-1" small v-if="busy.run"></b-spinner></b-btn>
      <b-btn class="mr-1" variant="primary" @click="" :disabled="busy.run">대표이미지로 지정<b-spinner class="ml-1" small v-if="busy.run"></b-spinner></b-btn>
      <b-btn class="mr-1" variant="success" @click="" :disabled="busy.run">EP이미지로 지정<b-spinner class="ml-1" small v-if="busy.run"></b-spinner></b-btn>
    </div>
<!--    <div class="image-table">
      <c-table :table-data="items.list" :fields="fields" :perPage.sync="perPage" :isBusy="busy.list" :getMoreBusy="busy.listmore"
               :hasMore="hasMore.list" :caption="items.list.length + ' 개 상품'" @btn-clicked="btnAction" @get-more="list(true)"></c-table>
    </div>-->

    <b-card class="image-table">
      <div class="row form-group">
        <div slot="header" class="col-form-label col-10">{{items.list.length}} 개 상품</div>
        <div class="clearfix col-2">
          <form class="pull-right form-inline"><select class="custom-select">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
          </select></form>
        </div>
      </div>
      <div class="table-responsive-sm">
        <table role="table" aria-busy="false" aria-colcount="7" class="table b-table">
          <thead role="rowgroup" class="">
            <tr role="row" class="">
              <th role="columnheader" scope="col" aria-colindex="1" class="">
                <b-form-checkbox v-model="allSelected.list" @input="toggleAll('list')"></b-form-checkbox>
              </th>
              <th v-for="(f, idx) in fields" role="columnheader" scope="col" :aria-colindex="idx + 2" :class="f.class" :style="f.style" :key="idx">
                <div v-html="f.label"></div>
              </th>
              <th role="columnheader" scope="col">
                <div style="width: 55px; text-align: center;">상세</div>
              </th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr role="row" v-for="(item, rowIdx) of items.list" :key="rowIdx">
              <td aria-colindex="1" role="cell" class="">
                <b-form-checkbox v-model="item.selected" @input="toggleOne()"></b-form-checkbox>
                <div class="custom-control custom-checkbox">
                  <input id="_checkbox0" type="checkbox" autocomplete="off" class="custom-control-input" :value="item.selected">
                </div>
              </td>
              <td>
                <div v-html="item._desc"></div>
              </td>
              <td class="td-img text-center">
                <template v-if="item._img_main">
                  <img :id="`pop-${rowIdx}-main`" class="img-thumb" :src="item._img_main" />
                  <b-popover :target="`pop-${rowIdx}-main`" placement="right" triggers="hover focus">
                    <img class="img-pop" :src="item._img_main" />
                  </b-popover>
                </template>
                <template v-else>미지정</template>
              </td>
              <td class="td-img text-center">
                <template v-if="item._img_ep">
                  <img :id="`pop-${rowIdx}-ep`" class="img-thumb" :src="item._img_ep" />
                  <b-popover :target="`pop-${rowIdx}-ep`" placement="right" triggers="hover focus">
                    <img class="img-pop" :src="item._img_ep" />
                  </b-popover>
                </template>
                <template v-else>미지정</template>
              </td>
              <td class="td-img">
                <span v-for="(i, idx) of item._imgs" :key="idx">
                  <img :id="`pop-${rowIdx}-${idx}`" class="img-thumb" :src="i" @click="selectImg(item, idx)"/>
                  <b-popover :target="`pop-${i}-${idx}`" placement="right" triggers="hover focus">
                    <img class="img-pop" :src="i" />
                  </b-popover>
                </span>
              </td>
              <td class="td-img text-center">
                <template v-if="item._img_ep_now">
                  <img :id="`pop-${rowIdx}-ep_now`" class="img-thumb" :src="item._img_ep_now" />
                  <b-popover :target="`pop-${rowIdx}-ep_now`" placement="right" triggers="hover focus">
                    <img class="img-pop" :src="item._img_ep_now" />
                  </b-popover>
                </template>
                <template v-else>미지정</template>
              </td>
              <td aria-colindex="7" role="cell" class="text-center" style="width: 75px;">
                <button type="button" class="btn mr-1 mb-1 btn-secondary btn-sm" @click="showModal(item)">상세</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>

  </div>
</template>

<style>
.drop {
  border: 2px dashed #bbb;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 50px;
  text-align: center;
  font: 20pt bold,"Vollkorn";
  color: #bbb;
  cursor: pointer;
}

.image-table .table th, .image-table .table td {
  padding: 0.5rem;
  letter-spacing: -0.5px;
}

.image-table .table td.td-img .img-thumb {
  width: 63px;
}
.image-table .table td.td-img .img-pop {
  width: 100%;
}
</style>

<script>
// import confirmedModal from '@/views/goods/ConfirmedModal.vue'

export default {
  name: 'ImageManage',
  title: '이미지 관리',
  components: {},
  data() {
    return {
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      godoCols: [],
      selectedGodoCols: [],
      form: {
        search: '',
        shop: [],
        goods_no: '',
        goods_id: '',
        gid: '',
        limit: 1000,
      },
      lastBody: {list: {}},
      items: {list: []},
      allSelected: {list: false},
      busy: {list: false, listmore: false, syncStockMulti: false, removeSoldoutOption: false, terminate: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController

      title: '',
      // item: {},
      itemAttr: {},
      imageIntent: 'default',
      fields: [
        // 'selected',
        {key: 'html1', label: `상품정보`, style: {minWidth: '200px'}},
        {key: 'html2', label: '대표이미지', class: 'text-center', style: {width: '85px'}},
        {key: 'html3', label: 'EP이미지', class: 'text-center', style: {minWidth: '75px'}},
        {key: 'html4', label: '이미지', class: 'text-center', style: {minWidth: '200px'}},
        {key: 'html5', label: 'EP썸네일', class: 'text-center', style: {minWidth: '75px'}},
        // {key: '_actions', label: '상세', style: {width: '55px', textAlign: 'center'}, buttons: [{label: '상세', event: 'show_modal'}]},
      ],
      perPage: 20,
      itemMode: 'list',
      picFilter: '',
      picFilteredCnt: 0,
      picGroup: 'ALL',
      picWidth: 175,
      picInfoTop: true,
      picInfoBottom: true,
      modal: {detail:false, displayStatus:false, diffList:false, image:false, field:false, godo:false},
      editMode: false,
      diff: null,
      priceHtml: '',
      displayStatus: 'view',
      displayStatusRelay: true,
      displayStatusReason: '',
      xlsx: {
        keys: [],
        labels: [],
      },
      pasteEventListener: null,
    }
  },
  async created() {
    let meta = await this.$api.getMeta('shop,brand,category');

    this.shop = meta.shop.filter(e=>e.use_yn==='y').sort((a,b)=>a.shop_id-b.shop_id);
    this.shop.forEach(s=>{
      s.value = s.boutique;
      s.label = `${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    });

    this.brand = meta.brand.map(e=>{
      return this.brandMap[e.brand_no] = {...e, value:e.brand_no, label:`${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a,b)=>a.label.localeCompare(b.label));

    this.category = meta.category.map(e=>{
      return this.categoryMap[e.category] = {...e, value:e.category, label:`${e.category} (${e.category_nm})`};
    }).sort((a,b)=>(a.value.length - b.value.length)*10 + a.value.localeCompare(b.value));

    this.list();

    this.pasteEventListener = async function (event) {
      let items = (event.clipboardData || event.originalEvent.clipboardData).items;
      // console.log(items); // will give you the mime types
      const prms = [];
      const results = [];
      for (let item of items) {
        const {kind, type} = item;
        // console.log(kind, type);

        let res;
        new Promise(resolve => prms.push(res = resolve));
        if (kind === 'string') {
          item.getAsString(e => {
            results.push({kind, type, data: e});
            res();
          });
        } else if (kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.onload = e => {
            results.push({kind, type, data: new Uint8Array(e.target.result)});
            res();
          };
          reader.readAsArrayBuffer(blob);
          // reader.readAsDataURL(blob);
        } else {
          res();
        }

        // console.log(item.kind, item.type);
        // await 을 하게 되면 데이터가 날라간다.
        // const str = await new Promise(resolve => item.getAsString(e => resolve(e)));
        // console.log(str);
      }
      await Promise.all(prms);
      console.log(results, results.length); // 이 시점에도 데이터가 들어와있지 않고 나중에 들어온다
    }
    document.addEventListener('paste', this.pasteEventListener);
  },
  beforeDestroy() {
    document.removeEventListener('paste', this.pasteEventListener);
  },
  methods: {
    async list(more) {
      let form = this.form;
      if (form.limit >= 10000 && !form.minimal) return alert('10000개 이상을 검색할때는 필수정보만 가져오기를 선택해주세요');

      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e=>e.shop_id); // 전체 선택일 경우 비우기
      let goods_no_include = this.form.goods_no ? this.form.goods_no.trim().split(/\D+/g).map(e=>+e) : [];
      let goods_id_include = this.form.goods_id ? this.form.goods_id.trim().split(/\r?\n/g).map(e=>e.trim()) : [];
      let gid = this.form.gid ? this.form.gid.trim().split(/\r?\n/g).map(e=>e.trim()) : [];

      let body = {...form, shop, goods_no_include, goods_id_include, gid};
      let j = await this.$api.postTable(this, '/goods/confirmed', body, {more, fnAssign:this.assignTableData});
    },
    assignTableData(e) {
      if (e.category) {
        let cates = [];
        for (let i = 3; i <= e.category.length; i += 3) {
          if (e.category.substring(i - 3, i) === '001') cates.push('전체');
          else cates.push(this.categoryMap[e.category.substring(0, i)] && this.categoryMap[e.category.substring(0, i)].category_nm || '?');
        }
        e.cate = cates.join(' > ');
      }

      const optionsHtml = `${e.options.filter(e=>!e.not_found).map(e=>
        `<span class="badge badge-light">${e.Size}</span><span class="badge badge-${e.stock>0?'success':'secondary'}" title="판매 가능수량">${e.stock}</span>`
        + (e.shop_stock != null && e.shop_stock !== e.stock ? `<span class="badge badge-${e.shop_stock>0?'primary':'secondary'}" title="SHOP 의 원본 수량">${e.shop_stock}</span>` : '')
        + (e.order_stock ? `<span class="badge badge-info" title="결제된 주문수량">${e.order_stock}</span>` : '')
        + (e.prepared_stock ? `<span class="badge badge-warning" title="결제 전 주문수량">${e.prepared_stock}</span>` : '')).join(', ')}`;
      e._desc = `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. ${e.shop} <i class="fa fa-external-link"></i></a> <span class="badge badge-warning">${e.brand_nm}</span>`
        + ` <span class="badge badge-light" title="${e.category}">${e.cate||''}</span> <a href="${this.MALL_URL}/shop/goods/goods_view.php?goodsno=${e.goods_no}" class="badge badge-info" target="_blank">${e.goods_no} <i class="fa fa-external-link"></i></a>`
        + `<br/>`
        + `${e.goods_nm}<br/><small><b>${e.options && e.options.length ? e.options[0].optnm : ''}</b></small> `
        + optionsHtml + '<br/>'
        + [e.goods_status, e.stock_status, e.display_status].map(e=>this.$utils.badge(e)).join(' ')
      ;
      const imgOrNoImg = imgObj => imgObj ? `https://i.balaan.io/${imgObj.thumb_200}` : '';
      const thumbImgUrl = (e, i) => `https://i.balaan.io/${e.image_path}${e.images[i].thumb_200}`;
      const squareImg = imgObj => imgObj ? `https://i.balaan.io/${e.image_path}${imgObj.square_jpg}` : '';
      e._img_main = imgOrNoImg(e.imgFixed && e.imgFixed.main);
      e._img_ep = imgOrNoImg(e.imgFixed && e.imgFixed.ep);
      e._imgs = (e.images ? e.images.map((img, i) => thumbImgUrl(e, i)) : e.img_urls);
      e._img_ep_now = squareImg(e.imgFixed && e.imgFixed.ep || e.images && e.images[0]);
    },

    showModal(row) {
      this.$refs.confirmedModal.showModal(row);
    },

    async imageProcessingModal() {
      let selected = this.items.list.filter(e=>e.selected);
      if (!selected.length) return alert('이미지를 재처리할 상품을 선택해주세요');
      this.modal.image = true;
    },
    async imageProcessing(goods_no) {
      // if (!goods_no && this.items.list.filter(e=>e.selected).some(e=>e.manual) && !confirm('파트너 직접관리상품이 포함되어 있습니다. 진행하시겠습니까?')) return;
      let j = await this.$api.postJson('/goods/requestImageProcessing', {goods_nos: goods_no ? [goods_no] : this.items.list.filter(e=>e.selected).map(e=>e.goods_no), intent: this.imageIntent, ignoreManual: true});
      if (j) {
        alert(`${j.cnt} 개 상품에 대해 이미지 작업이 시작되었습니다.` + (j.errors.length ? `\n${j.errors.length} 개의 에러가 발생했습니다:\n${j.errors.map(e=>e.error).join('\n')}` : ''));
        this.modal.image = false;
      }
    },
    btnAction(row, event) {
      if (event === 'show_modal') {
        this.showModal(row);
      }
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    async handleFile(event) {
      let file = (event.dataTransfer || event.target).files[0];
      console.log(file);
      console.log(await this.readFile(file));
      /*
        console.log(file.type);
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
        text/plain
        application/vnd.ms-excel
        text/x-sh
        application/json
       */
    },
    async readFile(file, {charset = 'utf-8'} = {}) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
          try {
            console.log(e.target.result);
            resolve(new Uint8Array(e.target.result));
          } catch (e) {
            reject(e);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    }
  }
}
</script>
